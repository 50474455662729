@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, canvas {

    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
      /* cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='),
    auto;   */
    cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iOCIgZmlsbD0iI0UwRTdGRiIvPgo8L3N2Zz4K'), auto
   
}

.develop-background {
  background: hsla(0, 0%, 100%, 1);

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  
  background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4F46E5", endColorstr="#3D35CE", GradientType=1 );

border-radius: 8px;
  
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);

  }

  .design-background {
    background: hsla(0, 0%, 100%, 1);

    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    background: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    
    background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));

  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4F46E5", endColorstr="#3D35CE", GradientType=1 );



  }

  
  .glowing-shadow {
    box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
    -webkit-box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
    -moz-box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
  }
  
  .hover-glowing-shadow-and-scale {
    box-shadow: 0px 0px 5px 5px rgba(223, 226, 252, 0.75);
    -webkit-box-shadow: 0px 0px 5px 5px rgba(223, 226, 252, 0.75);
    -moz-box-shadow: 0px 0px 5px 5px rgba(223, 226, 252, 0.75);
  }
  
  .hover-glowing-shadow-and-scale:hover {
    box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
    -webkit-box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
    -moz-box-shadow: 0px 0px 20px 5px rgba(223, 226, 252, 0.75);
    transform: scale(1.1);
  }
  
  .hover-glowing-shadow-and-scale:active {
    box-shadow: 0px 0px 20px 15px rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 20px 15px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 20px 15px rgba(255, 255, 255, 0.75);
    transform: scale(0.9);
  }


  
.glass{
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);

}


.contentp1 {
  padding-top: 10px;
  transform: translate3d(calc(50% + 40px), 0, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
}

.contentp1::before {
  content: "";
  position: absolute;
  top: 25px;
  left: -40px;
  height: 1px;
  width: 40px;
  background: black;
}


.menu-background {
  background: hsla(243, 75%, 59%, 1);

  background: linear-gradient(90deg, hsla(243, 75%, 59%, 0.1) 1%, hsla(243, 87%, 55%, 0) 100%);
  
  background: -moz-linear-gradient(90deg, hsla(243, 75%, 59%, 0.1) 1%, hsla(243, 87%, 55%, 0) 100%);
  
  background: -webkit-linear-gradient(90deg, hsla(243, 75%, 59%, 0.1) 1%, hsla(243, 87%, 55%, 0) 100%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4F46E5", endColorstr="#3026F0", GradientType=1 );

  

backdrop-filter: grayscale(100%);
-webkit-backdrop-filter: grayscale(100%);
-moz-backdrop-filter: grayscale(100%);
  }


  .designtalk-background {
    background: hsla(243, 75%, 59%, 1);
  
    background: linear-gradient(90deg, hsla(243, 75%, 59%, 0.2) 1%, hsla(243, 87%, 55%, 0) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(243, 75%, 59%, 0.2) 1%, hsla(243, 87%, 55%, 0) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(243, 75%, 59%, 0.2) 1%, hsla(243, 87%, 55%, 0) 100%);
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4F46E5", endColorstr="#3026F0", GradientType=1 );
  
    
  
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
    }
  


    

    .developbg-background {
      background: hsla(0, 0%, 100%, 1);
  
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
      background: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
      
      background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4F46E5", endColorstr="#3D35CE", GradientType=1 );
  
  
  
    }
  